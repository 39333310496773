import { Component, OnInit } from '@angular/core';
import { IdeaGenStateService } from '../../idea-gen-state.service';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { ThemeTrendsComponent } from '../theme-trends/theme-trends.component';
import { TopThemesComponent } from '../top-themes/top-themes.component';
import { MainNavbarComponent } from '../../../../core/components/main-navbar/main-navbar.component';
import { ClusterTreemapComponent } from '../../../results/components/result-sections/clustering/cluster-treemap/cluster-treemap.component';
import { FormControl, FormsModule } from '@angular/forms';
import { REGION_CODES } from '../../../../../../public/country-region-codes';
@Component({
  selector: 'app-idea-generation-home',
  standalone: true,
  imports: [CommonModule, SidebarComponent, ThemeTrendsComponent, TopThemesComponent, MainNavbarComponent, ClusterTreemapComponent, FormsModule],
  templateUrl: './idea-generation-home.component.html',
  styleUrl: './idea-generation-home.component.css'
})
export class IdeaGenerationHomeComponent implements OnInit {
  selectedOption: string = '';
  qipHighlightsTheme: string = '';
  endDate: string = '';
  marketCap: string = 'all';
  searchType:string = 'themes'; 
heading:string|null=null;
  roles: any;
  ngOnInit(): void {
    this.handleSubmit();
    this.roles=JSON.parse(localStorage.getItem('roles')!);
    // if(!this.roles.includes("alpha")){
    //   this.searchType="themes";
    // }
  }
  countriesControl = new FormControl<string[]>([]);
  expandedRegions: Set<string> = new Set();

  countryFilterOptions = REGION_CODES;

  constructor(private stateService: IdeaGenStateService) {
    // Subscribe to changes in selected option and market
    this.stateService.selectedOption$.subscribe((option) => {
      this.selectedOption = option;
    });
    this.stateService.selectedQipHighlight$.subscribe((theme) => {
      this.qipHighlightsTheme = theme
    })
    const today = new Date();
    this.endDate = today.toISOString().split('T')[0];
  }

  marketCapDisplay: string = 'All Market Cap';
  
  selectMarketCap(value: string) {
    this.marketCap = value;
    const displayMapping: { [key: string]: string } = {
      all: 'All Market Cap',
      large_cap: 'Large Cap',
      small_cap: 'Small Cap'
    };
    this.marketCapDisplay = displayMapping[value] || value;
  }
  
  getTechfieldHighlights(data: { query: string | null, entityids: [string] | null,name:string|null }) {
    this.marketCap = 'all';
    const displayMapping: { [key: string]: string } = {
      all: 'All Market Cap',
      large_cap: 'Large Cap',
      small_cap: 'Small Cap'
    };

    if (data.query == 'large_cap' || data.query == 'small_cap') {
      this.marketCap = 'large_cap';
      
      if (data.query == 'small_cap') {
        this.marketCap = 'small_cap';
      }
      data.query = null;
    }
 this.marketCapDisplay = displayMapping[this.marketCap] || this.marketCap;

    this.stateService.setQuery({
      market_cap: this.marketCap,
      countries: null,
      date_end: this.endDate,
      query: data.query,
      entityids: data.entityids,
      search_type:this.searchType
    });
    this.heading = data.name;
  }

  onSearchTypeChange(type: string) {
    this.searchType = type; // Update the search type

  }
  
  handleSubmit() {
    this.stateService.setSelectedOption('topFlopThemes', 'all');
    this.heading =null;

    this.stateService.setQuery({
      market_cap: this.marketCap,
      countries: this.countriesControl.value,
      date_end: this.endDate,
      query: null,
      entityids: null,
      search_type:this.searchType
    });
  }


  toggleExpand(region: string): void {
    if (this.expandedRegions.has(region)) {
      this.expandedRegions.delete(region);
    } else {
      this.expandedRegions.add(region);
    }
  }

  isExpanded(region: string): boolean {
    return this.expandedRegions.has(region);
  }

  toggleRegionSelection(region: any): void {
    const allSelected = this.isRegionFullySelected(region);
    const regionCountryCodes = region.countries.map((c: any) => c.code);

    if (allSelected) {
      const updatedSelection = (this.countriesControl.value ?? []).filter(
        (value: string) => !regionCountryCodes.includes(value)
      );
      this.countriesControl.setValue(updatedSelection);
    } else {
      const updatedSelection = Array.from(
        new Set([...(this.countriesControl.value ?? []), ...regionCountryCodes])
      );
      this.countriesControl.setValue(updatedSelection);
    }
  }

  toggleCountrySelection(countryCode: string, region: any): void {
    const currentSelection = this.countriesControl.value ?? [];
    const isSelected = currentSelection.includes(countryCode);

    if (isSelected) {
      this.countriesControl.setValue(
        currentSelection.filter((value: string) => value !== countryCode)
      );
    } else {
      this.countriesControl.setValue([...currentSelection, countryCode]);
    }
  }

  isRegionFullySelected(region: any): boolean {
    const regionCountryCodes = region.countries.map((c: any) => c.code);
    const selectedCodes = this.countriesControl.value ?? [];
    return regionCountryCodes.every((code: string) => selectedCodes.includes(code));
  }


}
