import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITreemapQuery } from '../../core/models/idea-generation.model';





@Injectable({
  providedIn: 'root',
})
export class IdeaGenStateService implements OnInit {
  private selectedOptionSubject = new BehaviorSubject<string>('topFlopThemes');
  private selectedMarketSubject = new BehaviorSubject<string>('all');
  private selectedQipHighlightsSubject = new BehaviorSubject<string>('uent_country any "RU"');

  selectedOption$ = this.selectedOptionSubject.asObservable();
  selectedMarket$ = this.selectedMarketSubject.asObservable();


  private   treemapQuerySubject = new BehaviorSubject<ITreemapQuery>({
    market_cap: 'all',       // Default market cap
    countries: [],          // Default country codes
    date_end: null,               // Default date
    query:null,
    entityids:null,
    search_type:""
  });

  
  treemapQuery$ = this.treemapQuerySubject.asObservable();


  ngOnInit(): void {
   this.setSelectedOption('topFlopThemes','all')
  }


  setQuery(query: ITreemapQuery) {
    this.treemapQuerySubject.next(query);
  }

  selectedQipHighlight$ = this.selectedQipHighlightsSubject.asObservable();
  constructor() {}

  setSelectedOption(option: string,market:string) {
    this.selectedOptionSubject.next(option);
    this.selectedMarketSubject.next(market);
  }
  setQipHighlightsTheme(qipHighlightsTheme:string) {
    this.selectedQipHighlightsSubject.next(qipHighlightsTheme);
  }


}
