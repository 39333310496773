import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITrendsResponse } from '../../../core/models/thematic.model';
import { ItopFlopClustersResponse, ItopFlopKeywordsData, ItopFlopKeywordsResponse, ItopFlopThemesResponse, ItopThemesResponse, ITreemapDataResponse, ITreemapQuery } from '../../../core/models/idea-generation.model';

@Injectable({
  providedIn: 'root'
})
export class IdeaGenerationServiceService {

  constructor(private http: HttpClient) { }

  getDevelopedMarketsData() {
    return this.http.get<ITrendsResponse>('/backend/thematicTrends/developed/2023', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("current_user_token")
      },
      responseType: 'json'
    })
  }

  getGlobalMarketsData() {
    return this.http.get<ITrendsResponse>('/backend/thematicTrends/ipc/2023', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("current_user_token")
      },
      responseType: 'json'
    })
  }

  getDevelopedMarketsTopThemes() {

    return this.http.get<ItopThemesResponse>('/backend/thematicTrends/top-themes/developed', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("current_user_token")
      },
      responseType: 'json'
    })
  }
  getGlobalMarketsTopThemes() {

    return this.http.get<ItopThemesResponse>('/backend/thematicTrends/top-themes/global', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("current_user_token")
      },
      responseType: 'json'
    })
  }


  getTreemapData(query:ITreemapQuery)  {
  
    return this.http.post<ITreemapDataResponse>('/backend/results/idea-generation/treemap',query, 
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("current_user_token")
        },
        responseType: 'json'
      })
  }


  getTopFlopThemes(query:ITreemapQuery) {
    return this.http.post<ItopFlopThemesResponse>('/backend/results/idea-generation/themes/top-flop', query,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("current_user_token")
        },
        responseType: 'json'
      })
  }

  getTopFlopClusters(query:ITreemapQuery) {
    return this.http.post<ItopFlopClustersResponse>('/backend/results/idea-generation/clusters/top-flop', query,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("current_user_token")
        },
        responseType: 'json'
      })
  }

  getTopFlopKeywords(query:ITreemapQuery) {
    return this.http.post<ItopFlopKeywordsResponse>('/backend/results/idea-generation/keywords/top-flop', query,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("current_user_token")
        },
        responseType: 'json'
      })
  }


}
