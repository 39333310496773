<app-main-navbar></app-main-navbar>

<div class="container-fluid">
  <div class="row" style=" display: flex; flex-grow: 1;">
    <div class="col-2">
      <div class="sidenav">
        <app-idea-generation-sidebar
          (techfieldHighlightsDataEvent)="getTechfieldHighlights($event)"></app-idea-generation-sidebar>
      </div>
    </div>


    <div class="col-10">
      <div class="main-content">
        <div class="text-container">
          <h3>Top & Flop Technologies</h3>
          <ng-container *ngIf="heading">
            <h4 style="font-size: 18px;color: blue;"> Focus Market: {{heading}}</h4>

          </ng-container>
          <br>
          <p>
            Our tool helps you identify the best and worst investment themes of the year by analyzing the growth in
            innovation.
          </p>
          <p>
            <strong>
              Top Technologies :
            </strong> These are the fields that have shown the most growth and are receiving more research and
            development (R&D) resources compared to the previous year. These are the areas you might want to consider
            investing in.
            <br>
            <strong>
              Flop Technologies :
            </strong>
            These are the fields with the least growth and are receiving fewer R&D resources. These are the areas you
            might want to avoid.
          </p>


        </div>
        <div>
          <p >
            Uncover the top and flop technologies within your universe, filtering by market cap, region and time. </p>
        </div>
        <div class="query-container">

          <div class="dropdown" *ngIf="roles.includes('alpha')">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
              {{ searchType }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <button class="dropdown-item" (click)="onSearchTypeChange('themes')">Themes</button>
              </li>
              <li>
                <button class="dropdown-item" (click)="onSearchTypeChange('clusters')">Clusters</button>
              </li>
              <li>
                <button class="dropdown-item" (click)="onSearchTypeChange('keywords')">Keywords</button>
              </li>
            </ul>
          </div>


          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton2"
              data-bs-toggle="dropdown" aria-expanded="false">
              {{ marketCapDisplay }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              <li>
                <button class="dropdown-item" (click)="selectMarketCap('all')">All Market Cap</button>
              </li>
              <li>
                <button class="dropdown-item" (click)="selectMarketCap('large_cap')">Large Cap</button>
              </li>
              <li>
                <button class="dropdown-item" (click)="selectMarketCap('small_cap')">Small Cap</button>
              </li>
            </ul>
          </div>


          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
              data-bs-toggle="dropdown" aria-expanded="false" style="    font-size: 14px;">
              <!-- Select Countries -->Country / Market
            </button>
            <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuButton"
              style="width: 270px; max-height: 300px; overflow-y: auto;">
              <!-- Loop through each region -->
              <div *ngFor="let region of countryFilterOptions">
                <!-- Region Header -->
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <input type="checkbox" [checked]="isRegionFullySelected(region)"
                      (change)="toggleRegionSelection(region)" id="{{ region.region }}-region-checkbox"
                      class="form-check-input me-2">
                    <label for="{{ region.region }}-region-checkbox" class="form-check-label">
                      {{ region.region }}
                    </label>
                  </div>
                  <button class="btn btn-sm btn-light" (click)="toggleExpand(region.region); $event.stopPropagation();">
                    <i class="bi" [ngClass]="isExpanded(region.region) ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
                  </button>
                </div>
                <!-- Countries in the Region -->
                <div *ngIf="isExpanded(region.region)" class="ms-3">
                  <div *ngFor="let country of region.countries" class="form-check">
                    <input type="checkbox" class="form-check-input" [id]="'country-' + country.code"
                      [checked]="(countriesControl.value ?? []).includes(country.code)"
                      (change)="toggleCountrySelection(country.code, region)">
                    <label class="form-check-label" [for]="'country-' + country.code">
                      {{ country.name }}
                    </label>
                  </div>
                </div>
                <hr class="my-2" *ngIf="region !== countryFilterOptions[countryFilterOptions.length - 1]">
              </div>
            </div>
          </div>

          <input id="endDate" class="form-control" type="date" [(ngModel)]="endDate" style="width:300px;" />

          <button class="btn btn-primary search-button" (click)="handleSubmit()">Submit</button>
        </div>



        <!-- Dynamically load components based on the selected option -->
        <ng-container *ngIf="selectedOption === 'topFlopThemes'||'qipHighlightsTechfields'">
          <app-top-themes></app-top-themes>
          <app-theme-trends *ngIf="searchType=='themes'"></app-theme-trends>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'qipHighlights'">
          <app-cluster-treemap [tabId]="'qipTabId'" [query]="qipHighlightsTheme" [entityids]="null" [tab_name]="null"
            [qipHighlights]="true">
          </app-cluster-treemap>
        </ng-container>


        <!-- Placeholder content when no option is selected -->
        <div *ngIf="!selectedOption">
          <h3>Select an option from the sidebar</h3>
        </div>
      </div>
    </div>
  </div>
</div>