import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, switchMap, of, tap } from 'rxjs';
import { IinnovationQuality } from '../../../../../../core/models/results.model';
import { loadSidenavData } from '../../../../../../state/action';
import { AppState } from '../../../../../../state/app.state';
import { selectTabById, selectSidenavDataByTabIdAndKey } from '../../../../../../state/selectors';
import { GeneralInnovationActivityResultsService } from '../../../../services/general-innovation-activity-results.service';
import { CommonModule } from '@angular/common';
import { PlotlyModule } from 'angular-plotly.js';

@Component({
  selector: 'app-innovation-quality',
  standalone: true,
  imports: [CommonModule, PlotlyModule],
  templateUrl: './innovation-quality.component.html',
  styleUrl: './innovation-quality.component.css'
})
export class InnovationQualityComponent implements OnInit, OnChanges {
  @Input() tabId!: string;
  @Input() query: string | null = null;
  @Input() entityids: string[] | null = null;
  @Input() tab_name: string | null = null;

  constructor(private store: Store<AppState>, private apiService: GeneralInnovationActivityResultsService
  ) {

  }

  params: any;
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabId']) {
      this.innovation_quality_data_loading_failed = false;
      this.innovation_quality_data = [];
      this.showinnovationQualityGraph = false;
      this.innovationQuality();

    }
  }



  innovation_quality_data_loading_failed: boolean = false;
  patent_badge_label: string = '';
  innovation_quality_data: IinnovationQuality[] = [];
  innovationQuality() {
    this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'company_innovation_quality')).pipe(
      take(1),
      switchMap((data) => {
        if (!data) {
          return this.apiService.getInnovationQuality(null, this.entityids).pipe(
            tap((res) => {
              if (res.payload != null) {

                this.store.dispatch(loadSidenavData({
                  tabId: this.tabId,
                  sidenavKey: 'company_innovation_quality',
                  data: res.payload
                }));

                this.innovation_quality_data = res.payload.data;
                this.patent_badge_label = res.payload.quality_badge_rating_label;

                this.plotInnovationQuality();
              } else {
                this.innovation_quality_data_loading_failed = true;
              }

            })
          );
        } else {
          this.innovation_quality_data = data.data;
          this.patent_badge_label = data.quality_badge_rating_label;

          this.plotInnovationQuality();

          return of();
        }
      })
    ).subscribe({
      next: () => {
        // console.log('innovationQuality data detailed processing complete.');
      },
      error: (err) => {
        // console.error('Error fetching tech innovationQuality data detailed:', err);
        this.innovation_quality_data_loading_failed = true;

      }
    });
  }

  showinnovationQualityGraph: boolean = false;
  innovationQualityGraph: any = [];
  patent_rating_image_url = '';

  plotInnovationQuality() {
    const years = this.innovation_quality_data.map(item => item.ype);
    const quality_average = this.innovation_quality_data.map(item => item.quality_average); 
    

    const colors = {
      'qip_dark_green': '#56751c',
      'qip_green': '#96c242',
      'qip_mint': '#dcefb9',
      'qip_muted_green': '#b2cc83',
      'qip_purple': '#96c242',
      'qip_dark_purple': '#5e1075',
      'qip_warning_orange': '#ff6700',
      'other_gray': '#cccccc'
    }

    const tick0 = Math.min(...years);
    const range = [Math.min(...years), Math.max(...years)];

    const trace = {
      x: years,
      y: quality_average,
      // mode: 'lines+markers',
      mode: 'lines+markers',
      type: 'scatter',
      name: 'Quality Average',
      line: { shape: 'linear', color: 'blue' },
      marker:{
        Symbol:'circle',
        color:'blue',
        size:8
      }
    };

    const layout = {
      title: {
        text: 'Average Yearly Quality Score',
        font: {
          size: 20,
          family: 'Arial, sans-serif',
          color: 'black',
          weight: 'bold'
        }
      },
      annotations: [
        {
          xref: 'paper',
          yref: 'paper',
          x: 0.5,
          y: 1.07,
          xanchor: 'center',
          yanchor: 'bottom',
          text: '* The latest year is incomplete and considered year-to-date. ',
          showarrow: false,
          font: {
            size: 12,
            color: 'gray'
          }
        },
        // {
        //   xref: 'paper',
        //   yref: 'paper',
        //   x: 0.5,
        //   y: 1.02,
        //   xanchor: 'center',
        //   yanchor: 'bottom',
        //   text: ' ** Patent Rating is based on the inventions published in the last 3 years. ',
        //   showarrow: false,
        //   font: {
        //     size: 12,
        //     color: 'gray'
        //   }
        // },

      ],
      xaxis: {
        title: 'Year',
        tickmode: 'linear',
        tick0: tick0,
        range:range,
        dtick: 1
      },
      yaxis: {
        title: 'Quality (%)',
        range: [0, 100]
      },
      shapes: [
        {
          type: 'rect',
          xref: 'paper',
          x0: 0,
          x1: 1,
          yref: 'y',
          y0: 0,
          y1: 50,
          fillcolor: colors.qip_warning_orange,
          opacity: 0.4,
          line: { width: 0 },
          name: 'B'
        },
        {
          type: 'rect',
          xref: 'paper',
          x0: 0,
          x1: 1,
          yref: 'y',
          y0: 50,
          y1: 70,
          fillcolor: colors.qip_muted_green,
          opacity: 0.4,
          line: { width: 0 },
          name: 'A'
        },
        {
          type: 'rect',
          xref: 'paper',
          x0: 0,
          x1: 1,
          yref: 'y',
          y0: 70,
          y1: 90,
          fillcolor: colors.qip_green,
          opacity: 0.4,
          line: { width: 0 },
          name: 'AA'
        },
        {
          type: 'rect',
          xref: 'paper',
          x0: 0,
          x1: 1,
          yref: 'y',
          y0: 90,
          y1: 100,
          fillcolor: colors.qip_dark_green,
          opacity: 0.4,
          line: { width: 0 },
          name: 'AAA'
        },
        {
          type: 'line',
          xref: 'paper',
          x0: years[0],
          x1: years[years.length - 1],
          yref: 'y',
          y0: 50,
          y1: 50,
          line: { color: 'red', width: 2, dash: 'dash' }
        },
        {
          type: 'line',
          xref: 'paper',
          x0: years[0],
          x1: years[years.length - 1],
          yref: 'y',
          y0: 70,
          y1: 70,
          line: { color: 'red', width: 2, dash: 'dash' }
        },
        {
          type: 'line',
          xref: 'paper',
          x0: years[0],
          x1: years[years.length - 1],
          yref: 'y',
          y0: 90,
          y1: 90,
          line: { color: 'red', width: 2, dash: 'dash' }
        },
        {
          type: 'line',
          xref: 'paper',
          x0: years[0],
          x1: years[years.length - 1],
          yref: 'y',
          y0: 100,
          y1: 100,
          line: { color: 'red', width: 2, dash: 'dash' }
        },
      ],
      width: 700,
      // height: 500,
      showlegend: false,
      // legend: { orientation: 'v' }
    };

    this.innovationQualityGraph = {
      data: [trace],
      layout: layout
    };

    this.showinnovationQualityGraph = true;




    // Set the image URL based on the latest quality rating
    this.patent_rating_image_url = 'assets/QuantIP_PatentRating_' + this.patent_badge_label + '.svg';
  }

}
