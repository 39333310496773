<div class="container-fluid">
  <div class="row">


    <div class="alert alert-info" role="alert" *ngIf="!showResults && !competitors_list_loading_failed">
      <p>
        This result is currently available only for companies classified under the GICS. Stay tuned as we work on
        adding this feature for all companies.
      </p>
    </div>

    <div class="spinner-border text-primary" role="status"
      *ngIf="showResults && company_data==undefined && !competitors_list_loading_failed">
      <span class="visually-hidden">Loading...</span>
    </div>


    <div class="alert alert-danger" role="alert" *ngIf="showResults && competitors_list_loading_failed">
      <p>
        Unfortunately, we weren't able to load the results. Please try again later, or for more assistance, please
        contact
        <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
      </p>
    </div>
    <div class="col m-10" style="margin:20px;" *ngIf="company_data">



      <div class="overall_rating">
        <span style="font-size: 28px;color: #a2c162;">
          Quant IP Innovation Rating
        </span>

        <div class="overall_rating_text">
          <span [style.color]="getCompetitivenessInfo(company_data!.overall_bracket).color" style="font-size: 100px;">
            {{company_data!.overall_bracket}}/5
          </span>&nbsp;
          <span style="font-size: 28px;margin-left: 55px;"
            [style.color]="getCompetitivenessInfo(company_data!.overall_bracket).color">{{getCompetitivenessInfo(company_data!.overall_bracket).text}}</span>
        </div>
        <div class="overall_description">
          <!-- {{company_data!.entity_name}} receives an evaluation of {{company_data!.overall_bracket}} out of 5: -->
          {{company_data!.combined_bracket_description}}
        </div>

      </div>

      <div class="summary_table">
        <table class="table table-hover table-condensed">
          <thead>
            <tr style="background-color: #96c242;">
              <th scope="col">Indicators </th>
              <th scope="col" class="score-col">Score</th>
              <th scope="col">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Portfolio Size</th>
              <td [style.color]="getCompetitivenessInfo(company_data!.invention_count_120m_bracket).color" style="font-weight: bolder;">
                {{company_data!.invention_count_120m_bracket}} / 5
              </td>
              <td>{{company_data!.invention_count_120m_bracket_description}} <a
                  (click)="onReadMoreClick($event, 'innovation_activity')"
                  style="color: blue; cursor: pointer; text-decoration: underline;">Read more</a>
              </td>
            </tr>

            <tr>
              <th>Recent Growth</th>
              <td [style.color]="getCompetitivenessInfo(company_data!.relative_growth_12m_bracket).color" style="font-weight: bolder;">
                {{company_data!.relative_growth_12m_bracket}} / 5
              </td>
              <td>{{company_data!.relative_growth_12m_bracket_description}} <a
                  (click)="onReadMoreClick($event, 'innovation_activity')"
                  style="color: blue; cursor: pointer; text-decoration: underline;">Read more</a></td>
            </tr>

            <tr>
              <th>Innovation Quality</th>
              <td [style.color]="getCompetitivenessInfo(company_data!.quality_average_12m_bracket).color" style="font-weight: bolder;">
                {{company_data!.quality_average_12m_bracket}} / 5
              </td>
              <td>{{company_data!.quality_average_12m_bracket_description}} <a
                  (click)="onReadMoreClick($event, 'innovation_quality')"
                  style="color: blue; cursor: pointer; text-decoration: underline;">Read more</a></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="sidenav-buttons">
        <button  class="btn sidenav-button" (click)="onReadMoreClick($event, 'competitive_benchmarking_peergroup')">
          Top Competitors
        </button>

        <button style="margin-left:40px;"  class="btn sidenav-button" (click)="onReadMoreClick($event, 'tech_footprint')">
          Technology Footprint
        </button>
      </div>
    </div>





  </div>
</div>