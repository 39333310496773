import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take, switchMap, tap, of } from 'rxjs';
import { IKeywords } from '../../../../../../core/models/results.model';
import { loadSidenavData } from '../../../../../../state/action';
import { AppState, Tab } from '../../../../../../state/app.state';
import { selectActiveTabDetails, selectSidenavDataByTabIdAndKey } from '../../../../../../state/selectors';
import { GeneralInnovationActivityResultsService } from '../../../../services/general-innovation-activity-results.service';
import { IClusteringFootprintDetailed, ITechFootprintDetailed } from '../../../../../../core/models/clustering.model';
import { ClusteringService } from '../clustering.service';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { NullableNumberPipe } from '../../../../../../shared/pipes/nullable-number.pipe';

@Component({
  selector: 'app-clustering-tech-footprint',
  standalone: true,
  imports: [CommonModule,NgxPaginationModule,NullableNumberPipe],
  templateUrl: './clustering-tech-footprint.component.html',
  styleUrl: './clustering-tech-footprint.component.css'
})
export class ClusteringTechFootprintComponent implements OnInit, OnChanges {
  @Input() tabId!: string;
  @Input() query: string | null = null; // Accept query
  @Input() entityids: string[] | null = null; // Accept entityids
  @Input() tab_name: string | null = null;
  constructor(private store: Store<AppState>, private clusteringService: ClusteringService) { }
  tabs$!: Observable<Tab | undefined>;
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabId']) {
      this.tabs$ = this.store.select(selectActiveTabDetails);
      this.cluster_footprint_data_loading_failed=false;
      this.tech_footprint_data_loading_failed=false;
      this.cluster_footprint_detailed_data=[];
      this.tech_footprint_detailed_data=[];
      this.techfootprintDetailed();
      this.clusterfootprintDetailed();
    }
  }

  cluster_footprint_detailed_data: IClusteringFootprintDetailed[] = [];
  paginator_1_page: number = 1;
  paginator_2_page: number = 1;
  tech_footprint_detailed_data: ITechFootprintDetailed[] = [];
  cluster_footprint_data_loading_failed:boolean=false;
 

  tech_footprint_data_loading_failed:boolean=false;
  techfootprintDetailed() {
    this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'clustering_tech_footprint_detailed')).pipe(
      take(1),
      switchMap((data) => {
        if (!data) {
          return this.clusteringService.getTechFootprintDetailed(this.query, this.entityids).pipe(
            tap((res) => {
              if(res.payload!=null){
                this.store.dispatch(loadSidenavData({
                  tabId: this.tabId,
                  sidenavKey: 'clustering_tech_footprint_detailed',
                  data: res.payload
                }));

                this.tech_footprint_detailed_data = res.payload;
              }else{
                this.tech_footprint_data_loading_failed=true;
              }

            })
          );
        } else {
          this.tech_footprint_data_loading_failed=false;
          this.tech_footprint_detailed_data = data;
          return of();
        }
      })
    ).subscribe({
      next: () => {
      },
      error: (err) => {
        this.tech_footprint_data_loading_failed=true;

      }
    });
  }


  clusterfootprintDetailed() {
    this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'clustering_footprint_detailed')).pipe(
      take(1),
      switchMap((data) => {
        if (!data) {
          return this.clusteringService.getClusteringDetailed(this.query, this.entityids).pipe(
            tap((res) => {
              if(res.payload!=null){
                this.store.dispatch(loadSidenavData({
                  tabId: this.tabId,
                  sidenavKey: 'clustering_footprint_detailed',
                  data: res.payload
                }));

                this.cluster_footprint_detailed_data = res.payload;
              }else{
                this.cluster_footprint_data_loading_failed=true;
              }

            })
          );
        } else {
          this.cluster_footprint_data_loading_failed=false;
          this.cluster_footprint_detailed_data = data;
          return of();
        }
      })
    ).subscribe({
      next: () => {
      },
      error: (err) => {
        this.cluster_footprint_data_loading_failed=true;

      }
    });
  }







}

