import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullableNumber',
  standalone: true
})
export class NullableNumberPipe implements PipeTransform {
  transform(value: number | null | undefined, format: string = '1.0-0', fallback: string = 'N/A'): string {
    if (value === null || value === undefined) {
      return fallback;
    }

    // Parse the format string to extract minimum and maximum fraction digits
    const [minDigits, maxDigits] = format
      .split('-')
      .map((part) => parseInt(part, 10));

    // Ensure defaults: minDigits = 0, maxDigits = 0 (no decimals if not specified)
    const minimumFractionDigits = isNaN(minDigits) ? 0 : minDigits;
    const maximumFractionDigits = isNaN(maxDigits) ? 0 : maxDigits;

    // Automatically strip unnecessary decimals for integers
    if (value % 1 === 0 && maximumFractionDigits === 0) {
      return new Intl.NumberFormat(undefined).format(value);
    }

    // Apply formatting for non-integer or when decimals are explicitly required
    return new Intl.NumberFormat(undefined, {
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(value);
  }
}
