<div class="container-fluid mt-4">
    <!-- Toggle Buttons -->

    <!-- <div class="query"  *ngIf="treemapQuery?.query">
      {{treemapQuery?.query}}
    </div> -->
    <div class="d-flex justify-content-right mb-3" *ngIf="showTable">
      <button
        class="btn"
        [ngClass]="{'btn-primary': showTopThemes, 'btn-outline-primary': !showTopThemes}"
        (click)="toggleThemeView(true)"
      >
      <ng-container *ngIf="treemapQuery?.search_type=='themes'">        Top Technologies
      </ng-container>
      <ng-container *ngIf="treemapQuery?.search_type=='clusters'">        Top Clusters
      </ng-container>

      <ng-container *ngIf="treemapQuery?.search_type=='keywords'">Top Keywords</ng-container>

      </button>
      <button
        class="btn ms-2"
        [ngClass]="{'btn-primary': !showTopThemes, 'btn-outline-primary': showTopThemes}"
        (click)="toggleThemeView(false)"
      >
      <ng-container *ngIf="treemapQuery?.search_type=='themes'">        Flop Technologies
      </ng-container>
      <ng-container *ngIf="treemapQuery?.search_type=='clusters'">        Flop Clusters
      </ng-container>

      <ng-container *ngIf="treemapQuery?.search_type=='keywords'">Flop Keywords</ng-container>

      </button>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <div *ngIf="showSpinner" class="spinner-border text-primary"
        style="width: 6rem; height: 6rem;align-items: center;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- Table -->
    <div class="table-responsive" *ngIf="showTable && this.treemapQuery?.search_type==='themes'">
      <table class="table table-striped table-bordered shadow-sm">
        <thead class="thead-dark">
          <tr>
            <th></th> <!-- Expand/Collapse Icon Column -->
            <th>Technologies</th>
            <th>
              Absolute Growth (12m)     <i 
              class="bi bi-info-circle" 
              style="margin-left: 5px; cursor: pointer;" 
              [matTooltip]="'Absolute Growth shows the number of inventions published in this technology field within the last 12 months by companies within your universe.'"
              matTooltipPosition="above">
            </i> <br>
              <span style="font-style: normal; font-weight: normal; margin-left: 5px;">
                Avg: {{ top_flop_themes.average_invention_count_12m | nullableNumber:'1.0-0'}} Inventions
              </span>
         
            </th>
            <th>
              Relative Growth (12m, %)   <i 
              class="bi bi-info-circle" 
              style="margin-left: 5px; cursor: pointer;" 
              [matTooltip]="'Relative Growth shows the relative increase/decrease in resource allocation, comparing the absolute growth of the last 12m to the previous 12 months.'"
              matTooltipPosition="above">
            </i> <br>
              <span style="font-style: normal; font-weight: normal; margin-left: 5px;">
                Avg: {{ top_flop_themes.average_relative_growth | nullableNumber:'1.0-1' }} %
              </span>
         
            </th>
            
       <th>Action</th>

          </tr>
        </thead>
        <tbody>
          
          <ng-container
            *ngFor="let theme of (showTopThemes ? top_flop_themes.top_themes : top_flop_themes.flop_themes); let i = index"
          >
            <!-- Main Row -->
            <tr>
              <!-- Expand/Collapse Icon -->
              <td class="text-center">
                <span
                  class="expand-icon"
                  role="button"
                  (click)="toggleCollapse(i)"
                  [ngClass]="{'expanded': expandedIndex === i, 'collapsed': expandedIndex !== i}"
                >
                  <!-- Dynamic Icon -->
                  <i
                    [class]="expandedIndex === i ? 'bi bi-dash-circle-fill' : 'bi bi-plus-circle-fill'"
                    aria-hidden="true"
                  ></i>
                </span>
              </td>
  
              <td>{{ theme.tf }}</td>
              <td>{{ theme.invention_count_12m }}</td>
              <!-- <td>{{ theme.invention_count_12m_prior }}</td> -->
              <td>
                <span [ngClass]="theme.relative_growth >= 0 ? 'text-success' : 'text-danger'">
                  {{ theme.relative_growth | nullableNumber: '1.2-2' }}%
                </span>
              </td>
              <td>
                <button class="btn search-button" (click)="submitTheme(theme)" >
                    Explore</button>
              </td>
            </tr>
  
            <!-- Collapsible Row -->
            <tr *ngIf="expandedIndex === i" class="collapse-row">
              <td colspan="6" class="p-0">
                <div class="card card-body">
                  <table class="table table-bordered mb-0">
                    <thead class="table-light">
                      <tr>
                        <th>Largest Players</th>
                        <th>Key Concepts</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <!-- Combine all entities into a single cell -->
                        <td>
                          <ul class="list-unstyled mb-0">
                            <li *ngFor="let entity of theme.entity_name| slice:0:5">
                              {{ entity }}
                            </li>
                          </ul>
                        </td>
                        <!-- Combine all keywords into a single cell -->
                        <td>
                          <ul class="list-unstyled mb-0">
                            <li *ngFor="let keyword of theme.ti_kw | slice:0:5">
                              {{ keyword }}
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>


    <!-- clusters table -->
    <div class="table-responsive" *ngIf="showTable && this.treemapQuery?.search_type=='clusters'">
      <table class="table table-striped table-bordered shadow-sm">
        <thead class="thead-dark">
          <tr>
            <th></th> <!-- Expand/Collapse Icon Column -->
            <th>Clusters</th>
            <th>
              Absolute Growth (12m)     <i 
              class="bi bi-info-circle" 
              style="margin-left: 5px; cursor: pointer;" 
              [matTooltip]="'Absolute Growth shows the number of inventions published in this cluster field within the last 12 months by companies within your universe.'"
              matTooltipPosition="above">
            </i> <br>
              <span style="font-style: normal; font-weight: normal; margin-left: 5px;">
                Avg: {{ top_flop_clusters.average_invention_count_12m | nullableNumber:'1.0-0' }} Inventions
              </span>
         
            </th>
            <th>
              Relative Growth (12m, %)   <i 
              class="bi bi-info-circle" 
              style="margin-left: 5px; cursor: pointer;" 
              [matTooltip]="'Relative Growth shows the relative increase/decrease in resource allocation, comparing the absolute growth of the last 12m to the previous 12 months.'"
              matTooltipPosition="above">
            </i> <br>
              <span style="font-style: normal; font-weight: normal; margin-left: 5px;">
                Avg: {{ top_flop_clusters.average_relative_growth | nullableNumber:'1.0-1' }} %
              </span>
         
            </th>
            
       <th>Action</th>

          </tr>
        </thead>
        <tbody>
          
          <ng-container
            *ngFor="let cluster of (showTopThemes ? top_flop_clusters.top_clusters : top_flop_clusters.flop_clusters); let i = index"
          >
            <!-- Main Row -->
            <tr>
              <!-- Expand/Collapse Icon -->
              <td class="text-center">
                <span
                  class="expand-icon"
                  role="button"
                  (click)="toggleCollapse(i)"
                  [ngClass]="{'expanded': expandedIndex === i, 'collapsed': expandedIndex !== i}"
                >
                  <!-- Dynamic Icon -->
                  <i
                    [class]="expandedIndex === i ? 'bi bi-dash-circle-fill' : 'bi bi-plus-circle-fill'"
                    aria-hidden="true"
                  ></i>
                </span>
              </td>
  
              <td>{{ cluster.th }}</td>
              <td>{{ cluster.invention_count_12m }}</td>
              <!-- <td>{{ theme.invention_count_12m_prior }}</td> -->
              <td>
                <span [ngClass]="cluster.relative_growth >= 0 ? 'text-success' : 'text-danger'">
                  {{ cluster.relative_growth | nullableNumber: '1.2-2' }}%
                </span>
              </td>
              <td>
                <button class="btn search-button" (click)="submitCluster(cluster)" >
                    Explore</button>
              </td>
            </tr>
  
            <!-- Collapsible Row -->
            <tr *ngIf="expandedIndex === i" class="collapse-row">
              <td colspan="6" class="p-0">
                <div class="card card-body">
                  <table class="table table-bordered mb-0">
                    <thead class="table-light">
                      <tr>
                        <th>Largest Players</th>
                        <th>Key Concepts</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <!-- Combine all entities into a single cell -->
                        <td>
                          <ul class="list-unstyled mb-0">
                            <li *ngFor="let entity of cluster.entity_name| slice:0:5">
                              {{ entity }}
                            </li>
                          </ul>
                        </td>
                        <!-- Combine all keywords into a single cell -->
                        <td>
                          <ul class="list-unstyled mb-0">
                            <li *ngFor="let keyword of cluster.ti_kw | slice:0:5">
                              {{ keyword }}
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>


    <!-- keywords table -->
    <div class="table-responsive" *ngIf="showTable && this.treemapQuery?.search_type=='keywords'">
      <table class="table table-striped table-bordered shadow-sm">
        <thead class="thead-dark">
          <tr>
            <th></th> <!-- Expand/Collapse Icon Column -->
            <th>Keywords</th>
            <th>
              Absolute Growth (12m)     <i 
              class="bi bi-info-circle" 
              style="margin-left: 5px; cursor: pointer;" 
              [matTooltip]="'Absolute Growth shows the number of inventions published in this keyword field within the last 12 months by companies within your universe.'"
              matTooltipPosition="above">
            </i> <br>
              <span style="font-style: normal; font-weight: normal; margin-left: 5px;">
                Avg: {{ top_flop_keywords.average_invention_count_12m | nullableNumber:'1.0-0' }} Inventions
              </span>
         
            </th>
            <th>
              Relative Growth (12m, %)   <i 
              class="bi bi-info-circle" 
              style="margin-left: 5px; cursor: pointer;" 
              [matTooltip]="'Relative Growth shows the relative increase/decrease in resource allocation, comparing the absolute growth of the last 12m to the previous 12 months.'"
              matTooltipPosition="above">
            </i> <br>
              <span style="font-style: normal; font-weight: normal; margin-left: 5px;">
                Avg: {{ top_flop_keywords.average_relative_growth | nullableNumber:'1.0-1' }} %
              </span>
         
            </th>
            
       <th>Action</th>

          </tr>
        </thead>
        <tbody>
          
          <ng-container
            *ngFor="let keyword of (showTopThemes ? top_flop_keywords.top_keywords : top_flop_keywords.flop_keywords); let i = index"
          >
            <!-- Main Row -->
            <tr>
              <!-- Expand/Collapse Icon -->
              <td class="text-center">
                <span
                  class="expand-icon"
                  role="button"
                  (click)="toggleCollapse(i)"
                  [ngClass]="{'expanded': expandedIndex === i, 'collapsed': expandedIndex !== i}"
                >
                  <!-- Dynamic Icon -->
                  <i
                    [class]="expandedIndex === i ? 'bi bi-dash-circle-fill' : 'bi bi-plus-circle-fill'"
                    aria-hidden="true"
                  ></i>
                </span>
              </td>
  
              <td>{{ keyword.ti_kw }}</td>
              <td>{{ keyword.invention_count_12m }}</td>
              <!-- <td>{{ theme.invention_count_12m_prior }}</td> -->
              <td>
                <span [ngClass]="keyword.relative_growth >= 0 ? 'text-success' : 'text-danger'">
                  {{ keyword.relative_growth | nullableNumber: '1.2-2' }}%
                </span>
              </td>
              <td>
                <button class="btn search-button" (click)="submitKeyword(keyword)" >
                    Explore</button>
              </td>
            </tr>
  
            <!-- Collapsible Row -->
            <tr *ngIf="expandedIndex === i" class="collapse-row">
              <td colspan="6" class="p-0">
                <div class="card card-body">
                  <table class="table table-bordered mb-0">
                    <thead class="table-light">
                      <tr>
                        <th>Largest Players</th>
                        <!-- <th>Key Concepts</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <!-- Combine all entities into a single cell -->
                        <td>
                          <ul class="list-unstyled mb-0">
                            <li *ngFor="let entity of keyword.entity_name| slice:0:5">
                              {{ entity }}
                            </li>
                          </ul>
                        </td>
                       
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  