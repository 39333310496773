<div class="container-fluid">
  <div class="row">
    <div class="col"
    style="padding:0;">
    <h3> Innovation Landscape </h3>

    <div class="text-container" *ngIf="showPlot">
      <p>Our Innovation Landscape shows how research and development (R&D) resources are spread across different technology fields:</p>
      <ul style="list-style-type: disc; padding-left: 20px;">
        <li>
          <strong>Size of Each Field:</strong> Indicates the absolute growth of the technology area in the last 12 months, highlighting the most active fields when it comes to inventions filed.
        </li>
        <li>
          <strong>Color of Each Field:</strong> Shows the relative growth compared to the previous 12 months, highlighting fields with the most potential.
        </li>
      </ul>
    </div>
    


      <div class="selectedTheme" *ngIf="selectedTheme">
        <h4>Selected Theme: <strong>
            {{selectedTheme}}
          </strong> </h4> &nbsp;&nbsp;&nbsp;&nbsp;
        <button class="btn search-button" (click)="submitTheme()" *ngIf="selectedTheme">
          Explore</button>
      </div>
      <!-- <div class="form-check form-switch toggle mt-2">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          [checked]="treemaptype === 'delta'"
          (change)="onSearchTypeToggle($event)">
        <label class="form-check-label" for="flexSwitchCheckDefault" style="color: blue;">
          Switch to {{ treemaptype === 'normal' ? 'delta' : 'normal' }} mode
        </label>
      </div> -->

      <div class="card  plotcard" style="min-height: 400px;">
        <div class="d-flex justify-content-center align-items-center">
          <div *ngIf="showSpinner" class="spinner-border text-primary"
            style="width: 6rem; height: 6rem;align-items: center;" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div>
          <plotly-plot *ngIf="showPlot &&!showSpinner" [data]="treemapDataValues.data"
            [layout]="treemapDataValues.layout"  (plotlyClick)="handleClick($event)"></plotly-plot>
        </div>
      </div>

      <div class="text-container" *ngIf="showPlot &&!showSpinner">
        Each block contains information on its growth, growth percentage, top keywords, and leading companies within the selected universe. Clicking on a theme allows you to conduct a thematic search, offering deeper insights such as top companies, top keywords, IPC classes, patent activity trends over the years, and technology footprint.      </div>
    </div>
  </div>
</div>

