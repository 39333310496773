<div class="container-fluid">
    <div class="row">
 
      <div class="col-12" style="margin:20px;">
        <div *ngIf=" (tabs$ | async)?.search_type=='company'">
          This page provides a closer look at what the company is focusing on in its innovation activity. The top keywords
          table displays the keywords that are more frequently seen in the text fields of the company’s patents in the
          last 3 years. The detailed footprint overview shows details of innovation activity in the technology fields the
          company is most active.
  
  
        </div>
  
        <div *ngIf=" (tabs$ | async)?.search_type=='theme'">
          This view gives a granular view on what is happening in this technology field through the most frequent keywords
          in the inventions, helping notice important areas of development within. These keywords can also be used to
          further specify your theme definitions.
        </div>
      </div>
  
      <div class="col m-10" style="margin:20px;">
        <div class="card keywords" style="padding:20px;">
  
            <h3>Top Technology Fields and Keywords</h3>
            <div class="alert alert-danger" role="alert" *ngIf="tech_footprint_data_loading_failed">
              <p>
                Unfortunately, we weren't able to load the data. Please try again later, or for more assistance, please
                contact
                <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
              </p>
            </div>
            <div class="spinner-border text-primary" role="status"
              *ngIf="tech_footprint_detailed_data.length==0 && !tech_footprint_data_loading_failed">
              <span class="visually-hidden">Loading...</span>
            </div>
            <table class="table   table-hover table-condensed " *ngIf="tech_footprint_detailed_data.length>0">
              <thead>
                <tr style="background-color: #96c242;">
                  <th>Techfield</th>
                  <th>Absolute Growth (3y)</th>
                  <th>Footprint Percentage (3y)</th>
                  <th>Top Keywords</th>
                  <th>Top Clusters</th>

                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of tech_footprint_detailed_data | paginate: {id:'pag_2', itemsPerPage: 10, currentPage: paginator_2_page }">
                  <td>{{item.tf}}</td>
                  <td>{{item.invention_count}}</td>
                  <td>{{item.invention_count_percentage | nullableNumber: '1.1-1'}} %</td>
                  <td [innerHTML]="item.top_keywords"></td>


                  <td [innerHTML]="item.top_clusters"></td>

    
                </tr>
              </tbody>
            </table>
            <pagination-controls id="pag_2" [maxSize]="5" (pageChange)="paginator_2_page = $event"
              style="align-items: flex-start;" *ngIf="tech_footprint_detailed_data.length>0">
            </pagination-controls>
    
    
          </div>
      
      </div>
  
  
  
  
      <div class="col m-10" style="margin:20px;">
        <div class="card keywords" style="padding:20px;">
  
            <h3>Top Clusters and Keywords</h3>
          <div class="alert alert-danger" role="alert" *ngIf="cluster_footprint_data_loading_failed">
            <p>
              Unfortunately, we weren't able to load the data. Please try again later, or for more assistance, please
              contact
              <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
            </p>
          </div>
          <div class="spinner-border text-primary" role="status"
            *ngIf="cluster_footprint_detailed_data.length==0 && !cluster_footprint_data_loading_failed">
            <span class="visually-hidden">Loading...</span>
          </div>
          <table class="table   table-hover table-condensed " *ngIf="cluster_footprint_detailed_data.length>0">
            <thead>
              <tr style="background-color: #96c242;">
                <th>Quant IP Clusters</th>
                <th>Absolute Growth(3y)</th>
                <th>Footprint Percentage (3y)</th>
                <th>Top Keywords</th>

              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let item of cluster_footprint_detailed_data | paginate: {id:'pag_2', itemsPerPage: 10, currentPage: paginator_2_page }">
                <td>{{item.tpc}}</td>
                <td>{{item.invention_count}}</td>
                <td>{{item.invention_count_percentage | nullableNumber: '1.1-1'}} %</td>
                <td [innerHTML]="item.top_keywords"></td>

              </tr>
            </tbody>
          </table>
          <pagination-controls id="pag_2" [maxSize]="5" (pageChange)="paginator_2_page = $event"
            style="align-items: flex-start;" *ngIf="tech_footprint_detailed_data.length>0">
          </pagination-controls>
  
  
        </div>
      </div>
    </div>