<div class="container-fluid">
  <div class="row">

    <div class="col m-10" style="margin:20px;">
      <div>
        The table below shows companies within the same GICS peer group of the company, ranked based on their innovation
        score, a composite score reflecting size, growth and quality, relative to the peer group.
      </div>
      <br>
      <br>

      <div class="alert alert-info" role="alert" *ngIf="!showResults && !competitors_list_loading_failed">
        <p>
          This result is currently available only for companies classified under the GICS. Stay tuned as we work on
          adding this feature for all companies.
        </p>
      </div>

      <div class="card keywords" style="padding:20px;" *ngIf="showResults">


        <h3>Detailed Technology Benchmark</h3>
        <br>

        <div class="alert alert-danger" role="alert" *ngIf="showResults && competitors_list_loading_failed">
          <p>
            Unfortunately, we weren't able to load the results. Please try again later, or for more assistance, please
            contact
            <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
          </p>
        </div>

        <div class="spinner-border text-primary" role="status"
          *ngIf="showResults &&competitors_list.length==0 && !this.competitors_list_loading_failed">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="filters" *ngIf="showResults && competitors_list.length > 0">

          <mat-slide-toggle (change)="filterFavorites()" >
            Show Selection Only
          </mat-slide-toggle>



          <input type="text" class="form-control" style="margin-left:20px;"  placeholder="Search by Company Name" [(ngModel)]="searchQuery"
            (ngModelChange)="applyFilters()" style="width: 270px;">



          <button class="btn search-button" style="margin-left:20px;" (click)="downloadExcel()" matTooltip="Download Excel">
            <i class="bi bi-download"></i>
          </button>





        </div>
        <table class="table table-hover table-condensed" *ngIf="showResults && competitors_list.length > 0">

          <thead>
            <tr style="background-color: #96c242;">
              <th scope="col" style="width: 70px;"></th>
              <th scope="col" style="width: 70px;">Rank</th>
              <th scope="col" style="max-width: 200px;">Company</th>

              <th scope="col">
                <div class="dropdown" style=" font-weight:normal;
">
                  <button class="btn country-dropdown-btn dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-bs-toggle="dropdown" aria-expanded="false" style="font-size: 14px;">
                    Country / <br>Market
                  </button>

                  <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuButton"
                    style="width: 270px; max-height: 300px; overflow-y: auto;">
                    <!-- Loop through each region -->
                    <div *ngFor="let region of countryFilterOptions">
                      <!-- Region Header -->
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <input type="checkbox" [checked]="isRegionFullySelected(region)"
                            (change)="toggleRegionSelection(region)" id="{{ region.region }}-region-checkbox"
                            class="form-check-input me-2">
                          <label for="{{ region.region }}-region-checkbox" class="form-check-label">
                            {{ region.region }}
                          </label>
                        </div>
                        <button class="btn btn-sm btn-light"
                          (click)="toggleExpand(region.region); $event.stopPropagation();">
                          <i class="bi" [ngClass]="isExpanded(region.region) ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
                        </button>
                      </div>
                      <!-- Countries in the Region -->
                      <div *ngIf="isExpanded(region.region)" class="ms-3">
                        <div *ngFor="let country of region.countries" class="form-check">
                          <input type="checkbox" class="form-check-input" [id]="'country-' + country.code"
                            [checked]="(countriesControl.value ?? []).includes(country.code)"
                            (change)="toggleCountrySelection(country.code, region)">
                          <label class="form-check-label" [for]="'country-' + country.code">
                            {{ country.name }}
                          </label>
                        </div>
                      </div>
                      <hr class="my-2" *ngIf="region !== countryFilterOptions[countryFilterOptions.length - 1]">
                    </div>
                  </div>
                </div>

              </th>

              

              <th>
                Size (10y)
                <i class="bi bi-info-circle" style="margin-left: 5px; cursor: pointer;" 
                   [matTooltip]="'Invention Count (10y). Number of inventions filed by the company or its subsidiaries, published in the last 120 months. Quantifies the total size of IP assets of the company.'"
                   matTooltipPosition="above">
                </i>
              </th>
              
              <th scope="col">
                Absolute Growth (1y)
                <i class="bi bi-info-circle" style="margin-left: 5px; cursor: pointer;" 
                   [matTooltip]="'Invention Count (1y). Number of inventions filed by the company or its subsidiaries, published in the last 12 months. Quantifies the growth of IP assets of the company.'"
                   matTooltipPosition="above">
                </i>
              </th>
              
              <th scope="col">
                Relative Growth (1y)
                <i class="bi bi-info-circle" style="margin-left: 5px; cursor: pointer;" 
                   [matTooltip]="'% Change, 1y. Percentage value showing how the innovation activity in the last year compares to the previous year. Quantifies the increase or decrease in the company’s innovation efforts.'"
                   matTooltipPosition="above">
                </i>
              </th>
              
              <th scope="col">
                Quality Score (0-100,1y)
                <i class="bi bi-info-circle" style="margin-left: 5px; cursor: pointer;" 
                   [matTooltip]="'Average of the quality score of inventions of the last 12 months. Quant IP Quality Score is a proprietary metric to evaluate the quality of an invention upon first publication. Taking into grant robustness, market potential, and citation potential into account, a larger average score at a company level indicates a higher patent portfolio value.'"
                   matTooltipPosition="above">
                </i>
              </th>
              
              <th>
                Innovation Score (0-100, 1y)
                <i class="bi bi-info-circle" style="margin-left: 5px; cursor: pointer;" 
                   [matTooltip]="'The innovation score is a combinatorial score for comparing the innovative strength of companies to their competitors. It takes size, growth, and quality into account and is normalized based on the peer group and countries selected.'"
                   matTooltipPosition="above">
                </i>
              </th>
              
              
    
              

              <th> Action</th>
            </tr>
          </thead>
          <tbody>
            <!-- Regular rows for the rest of the competitors -->
            <tr
              *ngFor="let company of filteredCompanyList | paginate: {id: 'pag_1', itemsPerPage: 20, currentPage: paginator_1_page }"
              [ngClass]="(company.entityid === this.entityid) ? 'highlight':'normal'">
              <td style="    width: 70px;">
                <button (click)="toggleFavorite(company)" class="btn btn-light" style="border: none;">
                  <i class="bi" [ngClass]="company.favorite ? 'bi-star-fill text-warning' : 'bi-star'"></i>
                </button>
              </td>
              <td style="width: 70px;">{{ company.rank }}</td>
              <td>
                <div class="text" style="max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                " matTooltip="{{company.entity_name}}">
                  {{ company.entity_name }}
                </div>
                <div class="isin" style="font-size: 12px;color: cadetblue;">
                  {{company.isin}} <a
                    [href]="company.webpage && company.webpage.startsWith('http') ? company.webpage : (company.webpage ? 'http://' + company.webpage : '#')"
                    target="_blank">
                    <i class="bi bi-globe2" style="color: grey;"></i>
                  </a>
                </div>
              </td>

              <td>{{ company.country }}</td>
              <td>{{ company.invention_count_120m | nullableNumber: '1.0-0' }}</td>
              <td>{{ company.invention_count_12m | nullableNumber: '1.0-0' }}</td>

              <td>{{ company.relative_growth_12m | nullableNumber: '1.0-1' }} %</td>
              <td>{{ company.quality_average_12m | nullableNumber: '1.0-1' }}</td>
              <td>
                <div class="score-bar-container">
                  <div class="score-bar">
                    <div class="score-bar-fill" [ngClass]="getBarColor(company.innovation_score)"
                      [style.width.%]="company.innovation_score"></div>
                  </div>
                  <span class="score-label">{{ company.innovation_score | nullableNumber: '1.0-1' }}</span>
                </div>

              </td>


              <td>
                <button class="btn search-button" (click)="newSearch(company)"
                  *ngIf="(company.entityid !== this.entityid)">
                  <i class="bi bi-box-arrow-up-right" style="color: black;font-size: 18px;"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <pagination-controls id="pag_1" [maxSize]="5" (pageChange)="paginator_1_page = $event"
          style="align-items: flex-start;" *ngIf="filteredCompanyList.length>0">
        </pagination-controls>
      </div>
    </div>
  </div>
</div>