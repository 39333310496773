<div class="container-fluid">
    <div class="row">
        <div class="col" style="margin:20px;">

     
            <div class="spinner-border text-primary" role="status"
                *ngIf="!showTreemap && !treemaps_data_loading_failed">
                <span class="visually-hidden">Loading...</span>
            </div>

            <plotly-plot *ngIf="showTreemap" [data]="themeCataloguePlot.data!" [layout]="themeCataloguePlot.layout!">
            </plotly-plot>


        </div>


    </div>


</div>