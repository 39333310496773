<div class="container-fluid">
  <div class="row">


    <div class="col m-10" style="margin:20px;">
      <div style="margin-bottom:10px;">
        The table below shows companies that are active with at least 1 invention in this area in the last 10 years,
        ranked based on the level of their innovative output in this area.
      </div>
      <div class="card keywords" style="padding:20px;">

        <h3>Top Companies in Theme</h3>
        <div class="spinner-border text-primary" role="status"
          *ngIf="top_companies_data.length==0 && !top_companies_loading_failed">
          <span class="visually-hidden">Loading...</span>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="top_companies_loading_failed">
          <p>
            Unfortunately, we weren't able to load the top companies. Please try again later, or for more assistance,
            please contact
            <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
          </p>
        </div>

        <div class="filters"   *ngIf="top_companies_data.length>0 && !top_companies_loading_failed">

          <mat-slide-toggle (change)="filterFavorites()">
            Show Selection Only
          </mat-slide-toggle>

         
          <input type="text" style="margin-left:20px;" class="form-control" placeholder="Search by Company Name" [(ngModel)]="searchQuery"
            (ngModelChange)="applyFilters()" style="width: 270px;">

            <button class="btn search-button" style="margin-left:20px;" (click)="downloadExcel()" matTooltip="Download Excel">
              <i class="bi bi-download"></i>
            </button>
  
        </div>
        <table class="table   table-hover table-condensed "
          *ngIf="top_companies_data.length>0 && !top_companies_loading_failed">
          <thead>
            <tr style="background-color: #96c242;">
              <th scope="col" style="width: 70px;"></th>
              <th scope="col" style="width: 70px;">Rank</th>

              <th scope="col">Company</th>
              <th scope="col">
                <div class="dropdown" style=" font-weight:normal;
">
                   <button class="btn country-dropdown-btn dropdown-toggle" type="button" id="dropdownMenuButton"
                   data-bs-toggle="dropdown" aria-expanded="false" style="font-size: 14px;">
                   Country / <br>Market
                 </button>
                  <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuButton"
                    style="width: 270px; max-height: 300px; overflow-y: auto;">
                    <!-- Loop through each region -->
                    <div *ngFor="let region of countryFilterOptions">
                      <!-- Region Header -->
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <input type="checkbox" [checked]="isRegionFullySelected(region)"
                            (change)="toggleRegionSelection(region)" id="{{ region.region }}-region-checkbox"
                            class="form-check-input me-2">
                          <label for="{{ region.region }}-region-checkbox" class="form-check-label">
                            {{ region.region }}
                          </label>
                        </div>
                        <button class="btn btn-sm btn-light"
                          (click)="toggleExpand(region.region); $event.stopPropagation();">
                          <i class="bi" [ngClass]="isExpanded(region.region) ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
                        </button>
                      </div>
                      <!-- Countries in the Region -->
                      <div *ngIf="isExpanded(region.region)" class="ms-3">
                        <div *ngFor="let country of region.countries" class="form-check">
                          <input type="checkbox" class="form-check-input" [id]="'country-' + country.code"
                            [checked]="(countriesControl.value ?? []).includes(country.code)"
                            (change)="toggleCountrySelection(country.code, region)">
                          <label class="form-check-label" [for]="'country-' + country.code">
                            {{ country.name }}
                          </label>
                        </div>
                      </div>
                      <hr class="my-2" *ngIf="region !== countryFilterOptions[countryFilterOptions.length - 1]">
                    </div>
                  </div>
                </div>

              </th>

             
              <th scope="col">
                In Theme Size (10y)
                <i class="bi bi-info-circle" style="margin-left: 5px; cursor: pointer;" 
                   [matTooltip]="'In-Theme Invention Count (10y). Number of inventions filed by the company or its subsidiaries, published in the last 120 months, related to the selected technology. Quantifies the total size of IP assets of the company in this technology.'"
                   matTooltipPosition="above">
                </i>
              </th>
              
              <th scope="col">
                Absolute Growth (1y)
                <i class="bi bi-info-circle" style="margin-left: 5px; cursor: pointer;" 
                   [matTooltip]="'In-Theme Invention Count (1y). Number of inventions filed by the company or its subsidiaries, published in the last 12 months, related to the selected technology. Quantifies the growth of IP assets of the company in this technology.'"
                   matTooltipPosition="above">
                </i>
              </th>
             
              
              <th scope="col">
                Relative Growth (1y)
                <i class="bi bi-info-circle" style="margin-left: 5px; cursor: pointer;" 
                   [matTooltip]="'In-Theme % Change, 1y. Percentage value showing how the innovation activity in the last year compares to the previous year in the technology. Quantifies the increase or decrease in the company’s innovation efforts into this technology.'"
                   matTooltipPosition="above">
                </i>
              </th>
              
              <th scope="col">
                Quality Score (1y)
                <i class="bi bi-info-circle" style="margin-left: 5px; cursor: pointer;" 
                   [matTooltip]="'In-Theme, 0-100, 1y. Quant IP Quality Score is a proprietary metric to evaluate the quality of an invention upon first publication. Taking into account robustness, market potential, and citation potential, a larger average score at a company level indicates a higher patent portfolio value.'"
                   matTooltipPosition="above">
                </i>
              </th>
              
              <th scope="col">
                Total Size (10y)
                <i class="bi bi-info-circle" style="margin-left: 5px; cursor: pointer;" 
                   [matTooltip]="'Number of inventions filed by the company or its subsidiaries, published in the last 120 months, in all technologies. Quantifies the total size of IP assets of the company.'"
                   matTooltipPosition="above">
                </i>
              </th>
              
              <th>
                Innovation Score (0-100, 1y)
                <i class="bi bi-info-circle" style="margin-left: 5px; cursor: pointer;" 
                   [matTooltip]=" 'The innovation score is a combinatorial score used for comparing the innovative strength of companies to their competitors. It takes size, growth, and quality into account and is normalized based on the peer group and countries selected.'"
                   matTooltipPosition="above">
                </i>
              </th>
              

              <th > Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let company of filteredCompanyList | paginate: {id:'pag_1', itemsPerPage: 20, currentPage: paginator_1_page }">
              <td style="    width: 70px;">
                <button (click)="toggleFavorite(company)" class="btn btn-light" style="border: none;">
                  <i class="bi" [ngClass]="company.favorite ? 'bi-star-fill text-warning' : 'bi-star'"></i>
                </button>
              </td>
              <td style="width: 70px;">{{ company.rank }}</td>

              <td>
                <div class="text" style="max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                " matTooltip="{{company.entity_name}}">
                  {{ company.entity_name }}
                </div>
                <div class="isin" style="font-size: 12px;color: cadetblue;">
                  {{company.isin}} <a
                    [href]="company.webpage && company.webpage.startsWith('http') ? company.webpage : (company.webpage ? 'http://' + company.webpage : '#')"
                    target="_blank">
                    <i class="bi bi-box-arrow-up-right"></i>
                  </a>
                </div>
              </td>
              <td >{{company.country}}</td>


              <td >{{ company.in_theme_invention_count_120m |   nullableNumber:'1.0-0'   }}</td>
              <td >{{ company.invention_count_12m |   nullableNumber:'1.0-0'   }}</td>
              
              <td>{{ company.relative_growth |   nullableNumber:'1.0-1'   }} %</td>
              <td >{{company.quality_average_12m |   nullableNumber:'1.0-1'  }}</td>
              <td >{{company.all_invention_count_120m |  nullableNumber:'1.0-0'}}</td>
              <!-- <td >{{company.innovation_score |   nullableNumber:'1.0-1'  }}</td> -->
              <td>
                <div class="score-bar-container">
                  <div class="score-bar">
                    <div
                      class="score-bar-fill"
                      [ngClass]="getBarColor(company.innovation_score)"
                      [style.width.%]="company.innovation_score"
                    ></div>
                  </div>
                  <span class="score-label">{{ company.innovation_score |   nullableNumber:'1.0-1'   }}</span>
                </div>
              </td>
              

              <td>
                <button class="btn search-button"  (click)="newSearch(company)">
                  <i class="bi bi-box-arrow-up-right" style="color: black;font-size: 18px;"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination-controls id="pag_1" [maxSize]="5" (pageChange)="paginator_1_page = $event"
          style="align-items: flex-start;" *ngIf="filteredCompanyList.length>0">
        </pagination-controls>


      </div>
    </div>





  </div>
</div>